.swiper .swiper-initialized .swipe {
  width: calc(100% - 40px);
  position: relative;
  margin-bottom: 20px;
  z-index: 5;
  @media screen and (min-width: 768px) {
    width: calc(100% - 200px);
    max-width: 600px;
    height: 400px;
   }

  @media screen and (min-width: 1200px) {
    width: calc(100% - 280px);
    max-width: 600px;
    height: 500px;

  }
}

.swiper-slide {
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 18px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-slider {
  width: 100%;
  max-width: 300px;
  height: 100%;
  max-height: 180px;
  border-radius: 3%;
  display: block;
  object-fit: cover;
  @media screen and (min-width: 768px) {
    width: 100%;
    max-width: 570px;
    height: calc(100% - 30px);
     max-height: 300px;
  }

  @media screen and (min-width: 1200px) {
    width: 100%;
    max-width: 670px;
    height: 400px;
    max-height: 480px;
  }
}

.swiper-button-prev {
  @media screen and (min-width: 768px) {
    left: 60px;
  }
  @media screen and (min-width: 1200px) {
    left: 120px;
  }
}

.swiper-button-next {
  @media screen and (min-width: 768px) {
    right: 60px;
  }
  @media screen and (min-width: 1200px) {
    right: 120px;
  }
}

.swiper-button-prev::after {
  color: rgb(10, 201, 22);
}

.swiper-button-next::after {
  color: rgb(10, 201, 22);
}

span.swiper-pagination-bullet,
.swiper-pagination-bullet-active {
  background-color: rgb(10, 201, 22);
  width: 12px;
  height: 12px;
}
