.footer {
  width: 100%;
  // height: 230px;
  background-color: rgb(10, 201, 22);
  padding: 10px;
  @media screen and (min-width: 768px) {
    height: 110px;
  }
}

.contact__icon__footer {
  display: none;
  @media screen and (min-width: 768px) {
    display: block;

    width: 120px;
    height: 120px;
    margin: -20px;
  }
}

.location_point {
  display: block;
  width: calc(100% / 2 + 60px);
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  @media screen and (min-width: 1200px) {
    display: flex;
    justify-content: space-around;
  }
}

.connect {
  width: fit-content;
  display: flex;
  justify-content: space-around;
  margin-left: auto;
  margin-right: auto;

  @media screen and (min-width: 768px) {
    margin-bottom: 15px;
    margin-bottom: 0;
  }
}

.footer__contact {
  width: 100%;
  color: #fff;
  align-items: center;
  @media screen and (min-width: 768px) {
    width: calc(100% - 150px);
    display: flex;
    margin: auto;
  }
}

.tell,
.mail {
  width: max-content;
  color: #fff;
  font-weight: 700;
  text-decoration: none;
  margin-top: auto;
  margin-bottom: auto;
}

.lng-footer-adress,
.lng-footer-point {
  font-size: 16px;
  width: fit-content;
  display: block;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
  margin-bottom: 5px;

  @media screen and (min-width: 768px) {
    font-size: 16px;
    margin-left: auto;
    margin-right: auto;
  }

  @media screen and (min-width: 1200px) {
    font-size: 18px;
    margin: auto;
  }
}

.lng-footer-job {
  display: block;
  font-size: 18px;
  margin: 0;
  @media screen and (min-width: 768px) {
    font-size: 28px;
  }
  @media screen and (min-width: 1200px) {
    font-size: 34px;
    margin: auto 15px;
  }
}

.lng-footer-name {
  display: block;
  font-size: 18px;
  margin-top: 7px;
  margin-bottom: 7px;

  align-items: center;
  @media screen and (min-width: 768px) {
    font-size: 20px;
    margin-left: 15px;
    margin-top: 0;
    margin-bottom: 0;
  }
  @media screen and (min-width: 1200px) {
    font-size: 26px;
    margin: auto;
    text-transform: uppercase;
  }
}

.adress {
  display: flex;
  max-width: 380px;
  margin-left: auto;
  margin-right: auto;
  @media screen and (min-width: 768px) {
    display: block;
    width: calc(100% / 2 - 60px);
    // margin-right: 20px;
    // margin-left: 15px;
    max-width: none;
  }
  @media screen and (min-width: 1200px) {
    display: flex;
  }
}

.footer__wing {
  display: block;
  width: 30px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
  margin-bottom: 5px;
  @media screen and (min-width: 768px) {
    width: 44px;
  }
  @media screen and (min-width: 1200px) {
    width: 40px;
  }
}
