.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);

  opacity: 1;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 7;
}

.backdrop.is-hidden {
  opacity: 0;
  pointer-events: none;
}

.backdrop.is-hidden .modal {
  transform: translate(-50%, -50%) scale(0.8);
}

.modal {
  width: calc(100% - 20px);
  max-width: 322px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ebebeb;
  border-radius: 25px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14),
    0px 2px 1px rgba(0, 0, 0, 0.2);

  z-index: 9;
}

.modal__img {
  display: block;
  margin-top: 17px;
  width: 110px;
  margin-left: auto;
  margin-right: auto;
  fill: #32c682;
  stroke: #32c682;
}

.modal__title {
  display: block;
  font-size: 28px;
  font-weight: 500;
  text-align: center;
  color: rgb(72, 75, 72);
}
.modal__content {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
  border-top: 1px solid rgba(158, 161, 158, 0.527);
}
.modal__icon {
  width: 26px;
  height: 26px;
  margin-right: 5px;
  margin-top: 12px;
}
.modal__text {
  font-size: 18px;
  font-weight: 500;
  color: rgb(72, 75, 72);
  margin-bottom: 8px;
}

.button {
  display: block;
  width: 150px;
  height: 44px;;
  padding: 4px;
  margin:  10px  auto ;
  font-size: 16px;
  font-weight: 500;
  border: 5px solid #e7e3e352;
  border-radius: 50px;
  background: #ebeef1;
  align-items: center;
  cursor: pointer;
  box-shadow: 7px 7px 15px rgba(10, 99, 169, 0.4),
    -7px -7px 15px rgba(255, 255, 255, 0.9);

  animation-duration: 360ms;

  transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1);

  fill: #32c682;
  stroke: #32c682;
  color: #32c682;
  &:hover,
  &:focus {
    fill: rgb(255, 51, 0);
    color: rgb(255, 51, 0);
    box-shadow: 
    7px 7px 15px rgba(10, 99, 169, 0.4),
    -7px -7px 15px rgba(255, 255, 255, 0.9),


    inset 5px 5px 5px -1px rgba(10, 99, 169, 0.7),
      inset -5px -5px 5px -1px rgba(255, 255, 255, 0.9);
  }
}

.button__icon {
  width: 28px;
  height: 26px;
  stroke-width: 1%;
  // margin: auto;
}

.button__text {
  
  margin: auto;
}