.section__link {
  width: 60px;
  height: 290px;
  display: block;
  background-color: rgba(255, 255, 255, 0.416);
  margin-top: auto;
  margin-bottom: auto;
  position: fixed;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3), 0px 4px 4px rgba(8, 62, 123, 0.4),
    1px 4px 6px rgba(79, 73, 73, 0.4);
  z-index: 6;
   top: 10%;
  @media screen and (min-width: 768px) {
   
  }
  @media screen and (min-width: 1200px) {
    width: 50px;
    height: 240px;
    top: 40%;
  }
}

ul {
  padding-inline-start: 0;
}

.link__list {
  gap: 5px;
  margin-top: 7px;
  margin-bottom: 7px;
  justify-content: space-around;
  // @media screen and (min-width: 768px) {
  // }

  // @media screen and (min-width: 1200px) {
  // }
}

.link__item {
  list-style-type: none;
  width: 50px;
  height: 50px;
  margin: 7px;

  @media screen and (min-width: 768px) {
  }

  @media screen and (min-width: 1200px) {
    width: 40px;
    height: 40px;
  }
}

.link {
  display: block;
  width: 100%;
  height: 100%;
  // @media screen and (min-width: 768px) {
  // }
  // @media screen and (min-width: 1200px) {
  // }
}

.link__img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
   transition-property: color;
  transition-duration: 250ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  
  &:hover,
  &:focus {
    padding: 3px;    
  }
}
