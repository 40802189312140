@import '~node_modules/modern-normalize/modern-normalize.css';
@import '../../node_modules/swiper/swiper-bundle.min.css';

body {
  background-color: rgba(96, 101, 96, 0.082);
  margin: 0;
  overscroll-behavior: none;
}

.background {
  width: 100%;
  height: 100vh;
  background-color: transparent;
  overscroll-behavior: none;
}

.bg_ios_back {
  background-attachment: local;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('../images/background.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  z-index: -1;
  overflow: auto;
 }
