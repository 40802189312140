.section__schedule {
  width: calc(100% - 20px);
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 30px;
}

.title_notar {
  font-size: 0;
  color: transparent;
}

.servise__list {
  width: calc(100% - 20px);
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  margin-top: 0;
  padding-top: 15px;
  @media screen and (min-width: 768px) {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    padding-top: 20px;
    margin-bottom: 20px;
  }

  @media screen and (min-width: 1200px) {
    gap: 15px;
  }
}

.lng-servise-item {
  width: 100%;
  max-width: 450px;
  text-decoration: none;
  list-style-type: none;
  margin-bottom: 15px;
  background-color: rgba(96, 101, 96, 0.747);

  margin-left: auto;
  margin-right: auto;
  border-radius: 8px;
  padding: 8px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3), 0px 4px 4px rgba(0, 0, 0, 0.4),
    1px 4px 6px rgba(0, 0, 0, 0.4);

  @media screen and (min-width: 768px) {
    display: flex;
    width: calc(100% / 2 - 10px);
    margin: 0;
    justify-content: center;
  }
  @media screen and (min-width: 1200px) {
    width: calc(100% / 3 - 10px);
  }
}
//-----------1-variant-servise-title---------
// .lng-servise-title {
//   font-size: 20px;
//   text-align: center;
//   font-weight: 500;
//   color: rgb(243, 168, 8);
//   margin: 5px;
//   padding: 10px;
//   border-radius: 8px;
//   border: 2px solid #fff;

//   @media screen and (min-width: 768px) {
//     display: flex;
//     width: calc(100% - 10px);
//     font-weight: 500;
//     justify-content: center;
//     align-items: center;
//   }

//   @media screen and (min-width: 1200px) {
//     // width: calc(100% / 3 - 10px);
//   }
// }
//--------------2-variant-servise-title-------

.lng-servise-title0,
.lng-servise-title1,
.lng-servise-title2,
.lng-servise-title3,
.lng-servise-title4,
.lng-servise-title5,
.lng-servise-title6,
.lng-servise-title7,
.lng-servise-title8,
  .lng-servise-title9 {
    font-size: 20px;
    text-align: center;
    font-weight: 500;
    color: rgb(243, 168, 8);
    margin: 5px;
    padding: 10px;
    border-radius: 8px;
    border: 2px solid #fff;

    @media screen and (min-width: 768px) {
      display: flex;
      width: calc(100% - 10px);
      font-weight: 500;
      justify-content: center;
      align-items: center;
    }
  
    @media screen and (min-width: 1200px) {
      // width: calc(100% / 3 - 10px);
    }

  }

.section__text {
  display: block;
  width: calc(100% - 20px);
  max-width: 450px;
  background-color: rgb(10, 201, 22);
  padding: 5px;
  border-radius: 8px;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3), 0px 4px 4px rgba(0, 0, 0, 0.4),
    1px 4px 6px rgba(0, 0, 0, 0.4);
  @media screen and (min-width: 768px) {
    width: calc(100% - 20px);
    max-width: 910px;
  }
  @media screen and (min-width: 1200px) {
    width: calc(100% - 14px);

    max-width: 1400px;
  }
}

.lng-text {
  font-size: 18px;
  text-align: center;
  padding: 10px;
  // color: rgb(96, 101, 96);
  color: rgb(255, 255, 255);
  margin: 5px;
  border: 2px solid rgb(255, 255, 255);
  border-radius: 8px;
  @media screen and (min-width: 768px) {
    font-size: 20px;
    font-weight: 700;
    margin: 20px;
    padding: 25px;
  }

  @media screen and (min-width: 1200px) {
    font-size: 24px;
    margin: 15px;
    text-align: center;
    // margin-top: 15px;
    // margin-bottom: 15px;
  }
}
