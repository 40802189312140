.header {
  width: 100%;
  background-color: rgb(106, 114, 106);
  padding: 10px;
}

.section {
  border: 2px solid rgb(255, 255, 255);
  border-radius: 8px;
}

h3 {
  margin: 0;
  margin-block-start: 0;
  margin-block-end: 0;
}
.header__section {
  width: calc(100% - 20px);
  margin-left: auto;
  margin-right: auto;

  @media screen and (min-width: 768px) {
    width: calc(100% - 60px);

    display: flex;
    justify-content: space-between;
  }
  @media screen and (min-width: 1200px) {
    justify-content: center;
    width: calc(100% - 50px);
  }
}

.logo {
  display: block;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: auto;
  margin-right: auto;

  @media screen and (min-width: 768px) {
    margin-left: 15px;
    margin-right: auto;

    padding-top: 15px;
    padding-bottom: 15px;
  }

  @media screen and (min-width: 1200px) {
    margin-left: 15px;
    margin-right: 100px;
  }
}

.logo__kyiv {
  display: none;
  @media screen and (min-width: 768px) {
    display: block;
    margin-left: -90px;
    margin-right: -150px;
  }

  @media screen and (min-width: 1200px) {
    display: none;
  }
}

.title__contact {
  @media screen and (min-width: 768px) {
    width: calc(100% - 200px);
  }

  @media screen and (min-width: 1200px) {
    display: flex;
    margin: auto;
  }
}

.header__title {
  width: 100%;
  text-align: center;
  @media screen and (min-width: 768px) {
    padding-top: 15px;
  }
  @media screen and (min-width: 1200px) {
    margin-left: 100px;
    margin-right: 100px;
  }
}

.lng-title-city {
  font-size: 14px;
  color: rgb(255, 255, 255);
}

.lng-title-job {
  font-size: 24px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 5 auto;
  padding: 0;
  color: rgb(243, 168, 8);
  @media screen and (min-width: 768px) {
    font-size: 32px;
    padding-top: 7px;
    padding-bottom: 7px;
  }
  @media screen and (min-width: 1200px) {
    font-size: 40px;
    padding-top: 7px;
    padding-bottom: 7px;
  }
}

.lng-title-name {
  text-align: center;
  color: rgb(243, 168, 8);
  margin: 0;
  padding: 0;
  margin-left: auto;
  margin-right: auto;

  font-size: 18px;
  margin-bottom: 10px;
  @media screen and (min-width: 768px) {
    font-size: 24px;
    margin-bottom: 10px;
  }
  @media screen and (min-width: 1200px) {
    font-size: 32px;
  }
}

.lng-title-certificate {
  font-size: 14px;
  color: rgb(255, 255, 255);
  @media screen and (min-width: 768px) {
    margin-bottom: 10px;
  }

  @media screen and (min-width: 1200px) {
  }
}

.contact {
  width: 100%;
  color: #fff;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-around;
  @media screen and (min-width: 768px) {
    justify-content: space-between;
  }

  @media screen and (min-width: 1200px) {
    width: fit-content;
    display: block;
    margin-top: auto;
    margin-bottom: auto;
  }
}

.contact__icon:hover .location:hover {
  display: none;
}

.contact__item {
  display: flex;
  font-size: 12px;
  font-weight: 700;
  margin: 0;
  padding: 0;
  color: #fff;
  text-decoration: none;
  align-items: center;
  @media screen and (min-width: 768px) {
    font-size: 16px;
    width: 100%;
  }

  @media screen and (min-width: 1200px) {
    width: calc(100% / 3);
    font-size: 18px;
    width: 100%;
  }

  transition-property: color;
  transition-duration: 250ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

  &:hover,
  &:focus {
    color: rgb(10, 201, 22);
  }

  &:hover .contact__icon,
  &:focus .contact__icon {
    fill: rgb(10, 201, 22);
    stroke: rgb(10, 201, 22);
  }
}

.location {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
  align-items: center;
  cursor: pointer;

  @media screen and (min-width: 768px) {
  }
  justify-content: space-between;
  @media screen and (min-width: 1200px) {
    justify-content: space-between;
  }
  &:hover .contact__icon,
  &:focus .contact__icon {
    fill: rgb(10, 201, 22);
    stroke: rgb(10, 201, 22);
  }
}

.location:last-child {
  margin-bottom: 0;
  @media screen and (min-width: 768px) {
  }
  @media screen and (min-width: 1200px) {
    margin-top: 25px;
  }
}

.contact__icon {
  width: 24px;
  height: 24px;
  fill: #fff;
  stroke: #fff;
  margin-right: 5px;
  @media screen and (min-width: 768px) {
  }
  margin-right: 5px;
  @media screen and (min-width: 1200px) {
    margin-right: 16px;
  }
  &:hover::after {
    fill: rgb(10, 201, 22);
    stroke: rgb(10, 201, 22);
  }
}

.change-lang {
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
  color: rgb(241, 181, 52);
  margin-left: auto;
  position: relative;
  top: -240px;
  left: calc(100% - 50px);

  @media screen and (min-width: 768px) {
    position: static;
    padding: 4px;
    margin-top: 5px;
    margin-right: -15px;
    margin-left: 15px;
    margin-bottom: auto;
    border-radius: 7px;
  }
  @media screen and (min-width: 1200px) {
    padding: 4px;
    margin-top: 5px;
    margin-right: -15px;
    margin-left: -20px;
    margin-bottom: auto;
    border-radius: 7px;
  }
}

.select__lng {  
  font-size: 16px;
  background-color: rgb(106, 114, 106);
  align-items: center;
  border: none;
  outline: none;
  color: #fff;
  margin-right: auto;
  margin-left: auto;
}

.language {
  display: block;
  @media screen and (min-width: 1200px) {
    width: 50px;
    background-color: transparent;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 35px;
    justify-content: center;
  }
}
li {
  display: block;
  text-decoration: none;
  list-style-type: none;
  margin-left: auto;
  margin-right: auto;
}

.header-item-lang-ru {
  @media screen and (min-width: 1200px) {
    border-top: 2px solid #fff;
    border-bottom: 2px solid #fff;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

.translate {
  display: block;
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  margin-left: auto;
  margin-right: auto;
}

.point {
  margin-bottom: 10px;
}
